import { useRef, useState, useCallback, useMemo } from 'react';

import { Avatar, Box, ButtonBase, Menu, MenuItem } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { Link as RouterLink } from 'react-router-dom';

import { useAmplify, useAuth } from 'src/hooks';
import { getConcatString } from 'src/utils';

import { useStyles } from './index.style';

const Account = () => {
  const classes = useStyles();
  const ref = useRef<HTMLDivElement>(null);
  const { signOut } = useAmplify();
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState(false);
  const { user } = useAuth();
  const { givenName, familyName } = user || {};

  const name = useMemo(
    () => getConcatString([givenName, familyName]),
    [familyName, givenName],
  );

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleLogout = useCallback(() => {
    try {
      handleClose();
      signOut();
      window.location.reload();
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Unable to logout', {
        variant: 'error',
      });
    }
  }, [signOut, enqueueSnackbar, handleClose]);

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        {...{ ref }}
      >
        <Avatar alt="User" className={classes.avatar} src="" />
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </>
  );
};

export default Account;
